import React from "react";
import { Container, Row } from "react-bootstrap";
import TitleHeading from "../../utils/title-heading/TitleHeading";
import styled from "styled-components";
import { CurrentService } from "../../assets/data/services/serviceLinks";

const MyServices = () => {
  const serviceLinks = CurrentService();

  return (
    <Container
      style={{
        marginTop: "100px",
      }}
    >
      <Row>
        <TitleHeading title={"Services I Offer"} />
      </Row>
      <Row>
        <BrandLogoWrapper data-aos-once="true" data-aos="fade-up">
          {serviceLinks.map((service, index) => (
            <a
              key={index}
              href={service.href}
              target="_blank"
              rel="noopener noreferrer"
              hrefLang="en-us"
              style={{
                textDecoration: "none",
                marginBottom: "20px",
              }}
              data-aos-once="true"
              data-aos="fade-up"
            >
              <BrandLogo data-aos-once="true" data-aos="fade-up">
                <img src={service.src} alt={service.alt} />
              </BrandLogo>
            </a>
          ))}
        </BrandLogoWrapper>
      </Row>
    </Container>
  );
};

export default MyServices;

const BrandLogo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px;

  width: 240px;
  height: 100px;
  margin-bottom: 1rem;
  background: #000000;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-shadow: 7px 7px 0px rgba(255, 255, 255, 0.1);
`;

const BrandLogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px;
  flex-wrap: wrap;
  margin-top: 80px;

  @media (min-width: 600px) {
    justify-content: space-between;
  }
`;
