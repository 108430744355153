import { useExternalMedia } from "../../../hooks/useExternalMedia";

export const CurrentService = () => {
  const { talentAcquisition, appointment, gmb } = useExternalMedia();

  const serviceLinks = [
    {
      href: "https://www.hrtechish.com/resume",
      src: talentAcquisition,
      alt: "Talent Acquisition",
    },
    {
      href: "https://era-tek.io/eratek-1kb-initiative",
      src: gmb,
      alt: "GMB",
    },
    {
      href: "https://calendly.com/bare/eratalk?month=2024-05",
      src: appointment,
      alt: "Book Appointment",
    },
  ];

  return serviceLinks;
};
